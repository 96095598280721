<template>
  <div class="h-100">
    <img class="po-a w-100" src="../../../assets/image/login-bg.png">
    <div class="dis-flex-com login">
      <div class="content" v-if="!companyListStatus">
        <div class="ft-sz-24 ft-w-b" v-if="tabInx == 1 || tabInx == 2 || tabInx == 4">
          <div>
            您好，
          </div>
          <div>
            欢迎<span v-if="tabInx != 4">来到</span><span v-else style="color: red;">注册</span>逑索云
          </div>
        </div>
        <div class="pd-t-30 dis-flex-row ju-c ft-sz-16" v-if="tabInx == 1 || tabInx == 2">
          <div class="dis-flex-row al-c pd-t-15">
            <div @click="tabClick(1)" :style="tabInx == 1 ?'color:#4A1DF6;':''">
              账号密码登录
            </div>
            <div class="pd-x-15">
              <div style="width: 2px;height: 20px;background-color: #F7F7F7;"></div>
            </div>
            <div @click="tabClick(2)" :style="tabInx == 2 ?'color:#4A1DF6;':''">
              验证码登录
            </div>
          </div>
        </div>
        <div class="pd-t-30" v-if="tabInx == 1">
          <div class="dis-flex-row al-c pd-b-15 bd-bott">
            <div class="mg-r-10">
              <img src="../../../assets/image/user.png">
            </div>
            <input class="flx-1 mobile-fd" type="text" v-model="zhDl.nakeName" placeholder="请输入用户名" />
          </div>

          <div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
            <div class="mg-r-10">
              <img src="../../../assets/image/pwd.png">
            </div>
            <input class="flx-1" type="password" v-model="zhDl.password" placeholder="请输入密码" />
          </div>

          <div class="mg-t-30">
            <button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="submintForm">登录</button>
          </div>
          <div class="pd-t-15">
            <button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="wxSubmintForm">微信授权一键登陆</button>
          </div>

          <div class="pd-t-20 dis-flex-row al-c ft-sz-16" style="color: #161321;">
            <div class="mg-l-auto" @click="tabClick(4)">
              注册
            </div>
            <div class="mg-l-15" @click="tabClick(3)">
              忘记密码?
            </div>
          </div>
        </div>
        <div class="pd-t-30" v-if="tabInx == 2">
          <div class="dis-flex-row al-c pd-b-15 bd-bott">
            <div class="mg-r-10">
              <img width="24" height="24" src="../../../assets/image/mobile.png">
            </div>
            <input class="flx-1 mobile-fd" type="number" v-model="zhDl.usermobile" placeholder="+86" />
          </div>

          <div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
            <div class="pd-r-15 dis-flex-row al-c loginCode-class">
              <div class="mg-r-10">
                <img width="24" height="24" src="../../../assets/image/code.png">
              </div>
              <input class="flx-1" v-model="zhDl.loginCode" placeholder="请输入验证码" />
            </div>
            <div :class="!canClick?'bk-co-66':'bk-co-47'" class="pd-x-10 pd-t-5 pd-b-5 ft-co-ff mg-l-auto" @click="countDown(0)">
              {{codecontent}}
            </div>
          </div>
          <div class="pd-t-30 mg-t-30">
            <button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="codeSubmint">登录</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="companyListStatus" class="h-100">
      <CompanyList :company_list="company_list"></CompanyList>
    </div>
  </div>
</template>

<script>
import http from '../../lib/api.js'
import util from '../../lib/util.js'
import CompanyList from '../../components/companyList.vue';
import { Toast } from 'vant';
var md5 = require('md5');
var { adminGeneral } = http
export default {
  name: 'Login',
  components: { CompanyList },
  data() {
    return {
      tabInx: 1,
      zhDl: {
        nakeName: '',
        password: '',
        usermobile: '',
        loginCode: '',
      },
      codecontent: '发送验证码',
      totalTime: 60,
      canClick: true,
      company_list: [],/*公司列表*/
      companyListStatus: false
    }
  },
  mounted() {
    // console.log(this.$route.query)
  },
  methods: {
    tabClick(val) {
      if (val == '3') {
        this.$router.push('/modify/1')
      } else if (val == '4') {
        if (util.getCaption(window.location.href, '?').indexOf('registcode=') != -1) {/*是否带公司邀请码*/
          this.$router.push('/registered?' + util.getCaption(window.location.href, '?'))
        } else {
          this.$router.push('/registered')
        }
      } else {
        this.tabInx = val;
      }
    },
    submintForm() {/*登录*/
      let s = this;
      if (s.zhDl.nakeName.replace(/(^\s*)|(\s*$)/g, "") == "" || s.zhDl.password.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('账号密码不能为空');
      adminGeneral.login.http({
        "username": s.zhDl.nakeName,
        "password": md5(s.zhDl.password),
        "type": 2
      }).then(res => {
        if (res.getret === 0) {
          s.succerLogin(res);
        } else {
          Toast(res.msg)
        }
      })
    },
    codeSubmint() {/*验证码登录*/
      let s = this;
      if (s.zhDl.usermobile.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('手机号码不能为空');
      if (!/^[1][0-9]{10}$/.test(s.zhDl.usermobile)) return Toast('请输入合法的手机号');
      if (s.zhDl.loginCode.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('验证码不能为空');
      adminGeneral.codeLogin.http({
        "mobile": s.zhDl.usermobile,
        "code": s.zhDl.loginCode,
        "type": 2
      }).then(res => {
        if (res.getret === 0) {
          s.succerLogin(res);
        } else {
          Toast(res.msg)
        }
      })
    },
    succerLogin(res) {/*登录成功统一处理*/
      let s = this;
      localStorage.setItem('user',JSON.stringify({ token: res.ui.token, userid: res.ui.userid }));
      s.company_list = res.info.company_list
      if (res.info.company_list.length == 0) {
        if (util.getCaption(window.location.href, '?').indexOf('registcode=') != -1) {/*是否带公司邀请码*/
          s.getCompany()
        } else {
          localStorage.removeItem('companyid')
          if (s.$route.query && s.$route.query.redirect) {
            s.$router.push(s.$route.query.redirect)
          } else {
            s.$router.push('/')
          }
          // Toast('等待审核通过方可进入')
        }
      } else if (res.info.company_list.length == 1) {
        localStorage.setItem('companyid',res.info.company_list[0].companyid)
        if (s.$route.query && s.$route.query.redirect) {
          s.$router.push(s.$route.query.redirect)
        } else {
          s.$router.push('/')
        }
      } else {
        s.companyListStatus = true;
      }
    },
    wxSubmintForm() {
      let s = this;
      adminGeneral.wxLogin.http({
        type: 7
      }).then(res => {
        if (res.getret === 0) {
          console.log(res.info.url)
          window.location.href = res.info.url;
        } else {
          Toast(res.msg)
        }
      })
    },
    switchCompanyList() {
      if (this.$route.query && this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect)
      } else {
        this.$router.push('/')
      }
    },
    countDown(type) { //发送验证码
      let s = this;
      if (s.zhDl.usermobile.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('手机号码不能为空');
      if (!/^[1][0-9]{10}$/.test(s.zhDl.usermobile)) return Toast('请输入合法的手机号');
      util.countDown(this, type)
    },
    getCompany() { /*查询公司*/
      let s = this;
      adminGeneral.codeQueryCompany.http({
        "condition": {
          registcode: util.getCaption(window.location.href, '?').substring(util.getCaption(window.location.href, '?').indexOf("=") + 1)
        }
      }).then(res => {
        if (res.getret === 0) {
          adminGeneral.joinCompany.http({/*加入公司*/
            "companyid": res.data.companyid,
          }).then(res => {
            if (res.getret === 0) {
              localStorage.setItem('companyid',res.data.companyid)
              if (s.$route.query && s.$route.query.redirect) {
                s.$router.push(s.$route.query.redirect)
              } else {
                s.$router.push('/')
              }
            } else {
              Toast(res.msg)
            }
          })
        } else {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>
<style scoped>
.login {
  z-index: 999;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: #161321;
}
.login .content {
  width: 100%;
  box-sizing: border-box;
  padding: 80px 50px 50px 50px;
}
button {
  border: none;
  color: #ffffff;
  border-radius: 23px;
  background: linear-gradient(to right bottom, #4478ff, #4a1df6);
  height: 46px;
}
button:focus {
  outline: none !important;
}
</style>
