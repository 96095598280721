<template>
	<div class="h-100">
		<img class="po-a w-100" src="../../../assets/image/login-bg.png">
		<div class="dis-flex-com login">
			<div class="content">
				<div class="ft-sz-24 ft-w-b">
					<div class="">
						您好，
					</div>
					<div class="">
						欢迎<span style="color: #4478FF;">注册</span>逑索云
					</div>
				</div>
				<div class="pd-t-30 dis-flex-row ju-c ft-sz-16">
					<div class="mg-r-10">
						<img width="24" height="24" src="../../../assets/image/mobile.png">
					</div>
					<input class="flx-1 mobile-fd" type="number" v-model="zhDl.usermobile" placeholder="+86" />
				</div>

				<div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
					<div class="pd-r-15 dis-flex-row al-c loginCode-class">
						<div class="mg-r-10">
							<img width="24" height="24" src="../../../assets/image/code.png">
						</div>
						<input class="flx-1" v-model="zhDl.registeredCode" placeholder="请输入验证码" />
					</div>
					<div :class="!canClick?'bk-co-66':'bk-co-47'" class="pd-x-10 pd-t-5 pd-b-5 ft-co-ff mg-l-auto" @click="countDown(2)">
						{{codecontent}}
					</div>
				</div>

				<div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
					<div class="mg-r-10">
						<img width="24" height="24" src="../../../assets/image/user.png">
					</div>
					<input class="flx-1" type="text" v-model="zhDl.registeredName" placeholder="请输入您的真实姓名" />
				</div>

				<div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
					<div class="mg-r-10">
						<img width="24" height="24" src="../../../assets/image/pwd.png">
					</div>
					<input class="flx-1" type="password" v-model="zhDl.registeredPswd" placeholder="请输入密码" />
				</div>

				<div class="dis-flex-row al-c pd-t-15 pd-b-15 bd-bott">
					<div class="mg-r-10">
						<img width="24" height="24" src="../../../assets/image/pwd.png">
					</div>
					<input class="flx-1" type="password" v-model="zhDl.registeredPswd1" placeholder="请再次输入密码" />
				</div>

				<div class="mg-t-30">
					<button class="pd-y-10 w-100 ft-co-ff ft-sz-16" type="button" @click="submintForm">立即注册</button>
				</div>

				<div class="pd-t-20 dis-flex-row al-c ft-sz-16" style="color: #161321;">
						<div class="mg-l-auto">
							已有账号?
						</div>
						<div style="color: #4478FF;" @click="pushGo">
							去登陆>>
						</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import {
		Toast
	} from 'vant';
	var md5 = require('md5');
	var {
		adminGeneral
	} = http
	export default {
		name: 'Statistical',
		components: {},
		data() {
			return {
				zhDl: {
					usermobile: '',
					registeredCode: '',
					registeredName: '',
					registeredPswd: '',
					registeredPswd1: ''
				},
				codecontent: '发送验证码',
				totalTime: 60,
				canClick: true
			}
		},
		mounted() {

		},
		methods: {
			pushGo() { /*返回*/
				this.$router.go(-1);
			},
			submintForm() {
				let s = this;
				if (s.zhDl.usermobile.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('手机号码不能为空');
				if (!/^[1][0-9]{10}$/.test(s.zhDl.usermobile)) return Toast('请输入合法的手机号');
				if (s.zhDl.registeredCode.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('验证码不能为空');
				if (s.zhDl.registeredName.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('验证码不能为空');
				if (s.zhDl.registeredPswd.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('密码不能为空');
				if (s.zhDl.registeredPswd1 != s.zhDl.registeredPswd1) return Toast('两次密码不一致');
				if(util.getCaption(window.location.href, '?').indexOf('registcode=') != -1){
					adminGeneral.registeredCy.http({
						"info": {
							"username":s.zhDl.usermobile,
							"usermobile": s.zhDl.usermobile,
							"realname": s.zhDl.registeredName,
							"userpwd": md5(s.zhDl.registeredPswd),
							"code": s.zhDl.registeredCode,
							registcode: util.getCaption(window.location.href, '?').substring(util.getCaption(window.location.href, '?').indexOf("=")+1)
						}
					}).then(res => {
						if (res.getret === 0) {
							Toast(res.msg)
							setTimeout(function() {
								s.$router.go(-1);
							}, 200)
						} else {
							Toast(res.msg)
						}
					})
				}else{
					adminGeneral.registered.http({
						"info": {
							"username":s.zhDl.usermobile,
							"usermobile": s.zhDl.usermobile,
							"realname": s.zhDl.registeredName,
							"userpwd": md5(s.zhDl.registeredPswd),
							"code": s.zhDl.registeredCode
						}
					}).then(res => {
						if (res.getret === 0) {
							Toast(res.msg)
							setTimeout(function() {
								s.$router.go(-1);
							}, 200)
						} else {
							Toast(res.msg)
						}
					})
				}
			},
			countDown(type) { //发送验证码
				let s = this;
				if (s.zhDl.usermobile.replace(/(^\s*)|(\s*$)/g, "") == "") return Toast('手机号码不能为空');
				if (!/^[1][0-9]{10}$/.test(s.zhDl.usermobile)) return Toast('请输入合法的手机号');
				util.countDown(this, type)
			}
		}
	}
</script>
<style scoped>
.login {
  z-index: 999;
	position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
	color: #161321;
}
.login .content{
	width: 100%;
	box-sizing: border-box;
	padding: 80px 50px 50px 50px;
}
button {
  border: none;
  color: #ffffff;
  border-radius: 23px;
  background: linear-gradient(to right bottom,#4478FF, #4A1DF6);
	height: 46px;
}
button:focus {
  outline: none !important;
}
</style>
